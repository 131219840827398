import commonEn from './Common/en.json';
import commonSin from './Common/si.json';
import commonZh from './Common/zh.json';

import itemEn from './Item/en.json';
import itemSin from './Item/si.json';
import itemZh from './Item/zh.json';

import drawerEn from './Drawer/en.json';
import drawerSin from './Drawer/si.json';
import drawerZh from './Drawer/zh.json';

import customerEn from './Customer/en.json';
import customerSin from './Customer/si.json';
import customerZh from './Customer/zh.json';

import dashboardEn from './Dashboard/en.json';
import dashboardSin from './Dashboard/si.json';
import dashboardZh from './Dashboard/zh.json';

import supplierEn from './Supplier/en.json';
import supplierSin from './Supplier/si.json';
import supplierZh from './Supplier/zh.json';

import userEn from './User/en.json';
import userSin from './User/si.json';
import userZh from './User/zh.json';

import grnEn from './Grn/en.json';
import grnSin from './Grn/si.json';
import grnZh from './Grn/zh.json';

import stockBalanceEn from './StockBalance/en.json';
import stockBalanceSin from './StockBalance/si.json';
import stockBalanceZh from './StockBalance/zh.json';

import reportsEn from './Reports/en.json';
import reportsSin from './Reports/si.json';
import reportsZh from './Reports/zh.json';

const en = {
  common: commonEn,
  item: itemEn,
  drawer: drawerEn,
  customer: customerEn,
  dashboard: dashboardEn,
  supplier: supplierEn,
  user: userEn,
  grn: grnEn,
  stock: stockBalanceEn,
  reports: reportsEn,
};

const si = {
  common: commonSin,
  item: itemSin,
  drawer: drawerSin,
  customer: customerSin,
  dashboard: dashboardSin,
  supplier: supplierSin,
  user: userSin,
  grn: grnSin,
  stock: stockBalanceSin,
  reports: reportsSin,
};

const zh = {
  common: commonZh,
  item: itemZh,
  drawer: drawerZh,
  customer: customerZh,
  dashboard: dashboardZh,
  supplier: supplierZh,
  user: userZh,
  grn: grnZh,
  stock: stockBalanceZh,
  reports: reportsZh,
};

export { en, si, zh };
