import { takeLatest, call, put } from 'redux-saga/effects';
import axios from '../../../config/axios';
import { fetchDataFail, fetchDataSuccess, FETCH_DATA } from '../../reducers/reports/salesReportReducer';

function* fetchSales({
  selectedFromDate,
  selectedToDate,
  userId,
  categoryId,
  counterId,
  shopId,
  itemCode,
  sortBy,
  orderBy,
  types,
}) {
  try {
    if (
      selectedFromDate &&
      selectedToDate &&
      orderBy &&
      sortBy &&
      userId &&
      categoryId &&
      itemCode &&
      counterId &&
      shopId &&
      types
    ) {
      const response = yield call(
        axios.get,
        `reports/salesreport?fromDate=${selectedFromDate}&toDate=${selectedToDate}&userId=${userId}&categoryId=${categoryId}&itemCode=${itemCode}&counterId=${counterId}&shopId=${shopId}&orderBy=${orderBy}&sortOrder=${sortBy}&type=${types}`,
      );
      if (response.data.success === true) {
        yield put(fetchDataSuccess(response.data.data));
      } else {
        yield put(fetchDataFail());
      }
    } else {
      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* fetchSalesSaga() {
  try {
    yield takeLatest(FETCH_DATA, fetchSales);
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}
