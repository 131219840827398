import { takeLatest, put, call } from 'redux-saga/effects';
import axios from '../../../config/axios';
import { FETCH_DATA, fetchDataSuccess, fetchDataFail } from '../../reducers/reports/dayEndSummaryReducer';

function* fetchDayEndSummary({ counterId, date }) {
  try {
    if (counterId && date) {
      const response = yield call(axios.get, `reports/userwisedayendsummary?date=${date}&counterId=${counterId}`);
      if (response.data.success === true) {
        yield put(fetchDataSuccess(response.data.data));
      } else {
        yield put(fetchDataFail());
      }
    } else {
      yield put(fetchDataFail());
    }
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* fetchDayEndSummarySaga() {
  try {
    yield takeLatest(FETCH_DATA, fetchDayEndSummary);
  } catch (error) {
    yield put(fetchDataFail(error));
  }
}
