export const SET_SELECTED_FROM_DATE = 'genius/history/invoiceHistory/SET_SELECTED_FROM_DATE';
export const SET_SELECTED_TO_DATE = 'genius/history/invoiceHistory/SET_SELECTED_TO_DATE';
export const SET_CUSTOMER_ID = 'genius/history/invoiceHistory/SET_CUSTOMER_ID';
export const SET_USER_ID = 'genius/history/invoiceHistory/SET_USER_ID';
export const SET_INVOICE_NUMBER = 'genius/history/invoiceHistory/SET_INVOICE_NUMBER';
export const SET_SHOP_ID = 'genius/history/invoiceHistory/SET_SHOP_ID';
export const RESET_DATA = 'genius/history/invoiceHistory/RESET_DATA';

export const FETCH_DATA = 'genius/history/invoiceHistory/FETCH_DATA';
export const FETCH_DATA_WITH_INVOICE_NO = 'genius/history/invoiceHistory/FETCH_DATA_WITH_INVOICE_NO';
export const FETCH_DATA_SUCCESS = 'genius/history/invoiceHistory/FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAIL = 'genius/history/invoiceHistory/FETCH_DATA_FAIL';

export const FETCH_INVOICE_DATA = 'genius/history/invoiceHistory/FETCH_INVOICE_DATA';
export const FETCH_INVOICE_DATA_SUCCESS = 'genius/history/invoiceHistory/FETCH_INVOICE_DATA_SUCCESS';
export const FETCH_INVOICE_DATA_FAIL = 'genius/history/invoiceHistory/FETCH_INVOICE_DATA_FAIL';

const initialState = {
  loading: false,
  invoiceData: [],
  invoiceHistory: [],
  selectedFromDate: new Date(),
  selectedToDate: new Date(),
  customerId: '0',
  userId: '0',
  invoiceNumber: '0',
  shopId: '1111',
  tableNo: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_FROM_DATE:
      return { ...state, selectedFromDate: action.selectedFromDate, loading: false };
    case SET_SELECTED_TO_DATE:
      return { ...state, selectedToDate: action.selectedToDate, loading: false };
    case SET_USER_ID:
      return { ...state, userId: action.userId, loading: false };
    case SET_CUSTOMER_ID:
      return { ...state, customerId: action.customerId, loading: false };
    case SET_SHOP_ID:
      return { ...state, shopId: action.shopId, loading: false };
    case SET_INVOICE_NUMBER:
      return { ...state, invoiceNumber: action.invoiceNumber, loading: false };
    case FETCH_DATA:
      return { ...state, loading: true };
    case FETCH_DATA_WITH_INVOICE_NO:
      return { ...state, loading: true };
    case FETCH_DATA_SUCCESS:
      return { ...state, invoiceHistory: action.invoiceHistory, loading: false };
    case FETCH_DATA_FAIL:
      return { ...state, loading: false, invoiceHistory: [] };
    case FETCH_INVOICE_DATA:
      return { ...state, loading: true };
    case FETCH_INVOICE_DATA_SUCCESS:
      return { ...state, invoiceData: action.invoiceData, tableNo: action.invoiceData.tableNo, loading: false };
    case FETCH_INVOICE_DATA_FAIL:
      return { ...state, loading: false };
    case RESET_DATA:
      return {
        ...state,
        selectedFromDate: initialState.selectedFromDate,
        selectedToDate: initialState.selectedToDate,
        shopId: initialState.shopId,
        invoiceNumber: initialState.invoiceNumber,
        userId: initialState.userId,
        customerId: initialState.customerId,
      };
    default:
      return state;
  }
};
export default reducer;

export const setFromDate = (selectedFromDate) => {
  return { type: SET_SELECTED_FROM_DATE, selectedFromDate };
};
export const setToDate = (selectedToDate) => {
  return { type: SET_SELECTED_TO_DATE, selectedToDate };
};
export const setUserId = (userId) => {
  return { type: SET_USER_ID, userId };
};
export const setCustomerId = (customerId) => {
  return { type: SET_CUSTOMER_ID, customerId };
};
export const setInvoiceNumber = (invoiceNumber) => {
  return { type: SET_INVOICE_NUMBER, invoiceNumber };
};
export const fetchData = (selectedFromDate, selectedToDate, customerId, userId, shopId) => {
  return {
    type: FETCH_DATA,
    selectedFromDate,
    selectedToDate,
    customerId,
    userId,
    shopId,
  };
};
export const fetchDataWithInvoice = (invoiceNumber) => {
  return {
    type: FETCH_DATA_WITH_INVOICE_NO,
    invoiceNumber,
  };
};
export const fetchDataSuccess = (invoiceHistory) => {
  return { type: FETCH_DATA_SUCCESS, invoiceHistory };
};
export const fetchDataFail = (err) => {
  return { type: FETCH_DATA_FAIL, err };
};
export const fetchInvoiceData = (invoiceId) => {
  return { type: FETCH_INVOICE_DATA, invoiceId };
};
export const fetchInvoiceDataSuccess = (invoiceData) => {
  return { type: FETCH_INVOICE_DATA_SUCCESS, invoiceData };
};
export const fetchInvoiceDataFail = (err) => {
  return { type: FETCH_INVOICE_DATA_FAIL, err };
};
export const setShopId = (shopId) => {
  return { type: SET_SHOP_ID, shopId };
};
export const resetData = () => {
  return { type: RESET_DATA };
};
